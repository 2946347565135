import $ from 'jquery';
import 'select2';                       // globally assign select2 fn to $ element
//import 'jqcloud'

$(() => {
    $('.select2-enable').select2();
});

$(window).on('load', function () {

    function displayForm1(event) {
        const [data, _status, xhr] = event.detail;
        $(".modal-holder").html(data.body);
        $(".modal-holder").show();
        var box = $("#wordCloud");
        var completion = box.data('samples-url');
        $.get({
            url: completion,
            dataType: 'json',
            success: function (data) {
                var cdv = $("#wordCloud")[0]
                //var words = []
                //words.concat(data);
                $(cdv).jQCloud(
                    data,
                    {width: 500, height: 500, autoResize: true}
                )
            }
        });
    };

    function displayForm2(event) {
        const [data, _status, xhr] = event.detail;
        $(".modal-holder").html(data.body);
        var box = $("#new_tag");
        var completion = box.data('samples-url');
        $("#new_tag").select2({
            tags: true,
            width: 300,
            ajax: {
                url: completion,
                dataType: "json",
                processResults: function (data) {
                    var tag_list = {results: data};
                    console.log(tag_list.toString())
                    return tag_list

                }
            }
        });
        $(".modal-holder").show();
    };

    let timeout;
    function displayNewSlide() {
        var slide_container = $("div.slide");
        var slide_url = slide_container.data('slide-url');
        var slide_number = parseInt($("#slide_number").val(), 10);
        var max_slide_number = parseInt($("#max_slide_number").val(), 10);
        var width = $(window).width() - 30;
        var height = $(window).height() - 70;
        if (slide_number < max_slide_number) {
            $('#slide_number').val(slide_number + 1);
        } else {
            $('#slide_number').val(0);
        }
        $.post({
            url: slide_url,
            dataType: 'html',
            data: {
                'slide_number': slide_number,
                'height': height,
                'width': width
            },
            success: function (data) {
                $("div.slide").html(data);
                timeout = window.setTimeout(displayNewSlide, 10000);
            }
        });
    }
    let element = $(".flexslider");
    //If it isn't "undefined" and it isn't "null", then it exists.
    if(typeof(element) != 'undefined' && element != null) {
        window.clearTimeout(timeout);
    //    timeout = window.setTimeout(displayNewSlide, 8000);
    } else {
        window.clearTimeout(timeout);
    };
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $(document).on('ajax:success', "a.filter-tag",
        displayForm1);
    $(document).on('ajax:success', "a.add-tag",
        displayForm2);
    $(document).on('ajax:success', 'form.modal', function (event) {
        const [data, _status, xhr] = event.detail;
        const url = xhr.getResponseHeader('Location');
        if (url) {
            // Redirect to url
            window.location = url;
            data - modal
        } else {
            // Remove old modal backdrop
            $('.modal-backdrop').remove();
            // Update modal content
            const modal = $(data).find('body').html();
            $(modal_holder_selector).html(modal).find(modal_selector).modal();
        }

        return false;
    });
    $(document).on('change', "select#Per_Page",
        function (event) {
            $.post($(event.target).data('bio'),
                {'per_page': $(event.target).val()},
                function () {
                    location.reload();
                });
        });

});
